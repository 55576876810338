/**
 * Created by enes.kahrovic@thebeaverhead.com on 24/10/2018.
 */

import React, { useCallback, useEffect, useMemo } from "react";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./CalendarsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { getCalendarCurrentData } from "../../../actions/calendar";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import { formatError } from "../../../helpers/errors";
import { calendarFeaturesCheck, updateJquery } from "../tools";
import NotificationPopup from "../component/NotificationPopup";
import useNotificationToast from "../hooks/useNotificationToastHook";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import appConfig from "../../../appConfig";
import { WT } from "../consts/widget";
import SortableTable from "../component/SortableTable";
import { useSortableTable } from "../component/SortableTable/useSortableTable";
import { useSortableTablesStore } from "../../../hooks/redux/sortableTables";

const MailchimpInstructionsPage = (props) => {
  return (
    <React.Fragment>
      <PageTemplate
        sidebarId="mailchimp"
        title={"Mailchimp"}
        iconImg={
          <img
            className={"img-responsive img-mailchimp_breadcrumb"}
            alt="Email"
            src={`/img/install_icons/mailchimp.png`}
          />
        }
        headerControls={[
          <Link
            key={"/calendar/new"}
            to="/calendar/new"
            className="btn btn-primary btn-rounded pull-right d-flex"
          >
            <i className="material-icons">open_in_new</i> Create new
          </Link>,
        ]}
      >
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <h3>Welcome to the Mailchimp Email Template Builder!</h3>
            </div>
            <div className={"col-xs-12 col-sm-4 col-md-6"}>
              <div className={"w-100 h-75 m-b-20"}>
                <iframe
                  src="https://player.vimeo.com/video/1025224816?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Embedding into Wordpress"
                  width={"100%"}
                  height={"100%"}
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
              <div className={"d-flex gap-xs justify-content-center"}>
                <a
                  href={
                    "http://help.display.church/en/articles/10068292-mailchimp-email-template-alpha"
                  }
                  target={"_mc_instructions"}
                  className={"btn btn-secondary"}
                >
                  Full Article & Walkthrough{" "}
                </a>
                <Link to={"/calendars"} className={"btn btn-primary"}>
                  Start: Go to Calendars Page
                </Link>
              </div>
            </div>
            <div className={"col-xs-12 col-sm-8 col-md-6"}>
              <h5>
                Quick Steps to Access the Mailchimp Alpha Testing Email Template
              </h5>
              <ol>
                <li>
                  Create a New Calendar
                  <p>
                    Navigate to calendars and create a new one or select an
                    existing calendar.
                  </p>
                </li>
                <li>
                  Set Up Your Calendar
                  <p>
                    Adjust design and settings, including the number of events
                    to display.
                  </p>
                </li>
                <li>
                  Publish and Install the Template
                  <ol>
                    <li>Click the Install tab.</li>
                    <li>Toggle the Publish button on.</li>
                    <li>Choose Mailchimp for customization and click Next.</li>
                  </ol>
                </li>
                <li>
                  Select Display Options
                  <p>
                    Choose between Static (one-time events) or Evergreen
                    (recurring events) newsletters.
                  </p>
                </li>
                <li>
                  Copy HTML Code
                  <p>
                    Click Copy Template and paste the HTML into Mailchimp.
                    Adjust titles, dates, colors, and other design elements.
                    Check the email preview and send a test email.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </PageTemplate>
    </React.Fragment>
  );
};

export default MailchimpInstructionsPage;
